import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import SectionComponent from 'src/components/SectionComponent'
import { WIDTH_MD } from 'src/utils/breakpoints'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OsMelhoresBeneficiosJSON from '../../assets/data/os-melhores-beneficios.json'
import { SuperAppInterProps } from '../../types'

import { ContainerDinDin, Card } from './styles'

type MelhoresBeneficiosProps = {
  icon: string;
  title: string;
  description: string;
}

const OsMelhoresBeneficios = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='os-melhores-beneficios'
      SectionStyles='pt-4 pb-5 bg-orange--extra'
      RowStyles='text-md-center justify-content-md-center'
      minHeight={{ sm: '585px', md: '958px', lg: '400px', xl: '400px' }}
    >
      <div className='col-xl-12'>
        <h2 className='font-citrina text-white fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xxl-48 lh-xxl-60 fw-600 mb-3'>
          Os melhores benefícios para quem usa o Meu Porquinho
        </h2>
        <p className='text-white fs-14 lh-17 fs-md-16 lh-md-20 mb-4'>
          Entenda por que vale a pena guardar o seu dinheiro todo mês
        </p>
      </div>
      <div className='col-xl-12'>
        {width < WIDTH_MD
        ? (
          <a
            href='https://inter-co.onelink.me/Qyu7/jyc4nxhk'
            title='Ativar agora'
            className='btn btn--lg bg-white text-orange--extra text-none mb-2'
            onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Os melhores benefícios para quem usa o Meu Porquinho',
                  element_action: 'click button',
                  element_name: 'Ativar agora',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/jyc4nxhk',
                })
            }}
          >
            Ativar agora
          </a>
        ) : (
          <button
            className='btn btn--lg bg-white text-orange--extra text-none mb-2'
            onClick={() => {
              setOpenModal && setOpenModal(true)
              setDataLayer(dataLayer)
              sendDatalayerEvent({
                section: 'dobra_06',
                section_name: 'Os melhores benefícios para quem usa o Meu Porquinho',
                element_action: 'click button',
                element_name: 'Ativar agora',
              })
            }}
          >
            Ativar agora
          </button>
        )}
      </div>
      {width < WIDTH_MD
        ? (
          <ContainerDinDin className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 70 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
            >
              {OsMelhoresBeneficiosJSON.map((item: MelhoresBeneficiosProps) => (
                <Card
                  className='d-flex position-relative justify-content-center flex-column'
                  key={item.title}
                >
                  <OrangeIcon size='MD' color='#6A6C72' icon={item.icon} />
                  <h3 className='text-grayscale--500 fs-16 lh-20 mb-0'>{item.title}</h3>
                  <p className='text-grayscale--400 fs-14 lh-17 mb-0'>{item.description}</p>
                </Card>
              ))}
            </DefaultCarousel>
          </ContainerDinDin>
        )
        : (
          <ContainerDinDin className='row mx-md-0 mt-md-4 justify-content-md-start justify-content-lg-center'>
            {OsMelhoresBeneficiosJSON.map((item: MelhoresBeneficiosProps) => (
              <Card
                className='d-flex position-relative justify-content-center flex-column'
                key={item.title}
              >
                <OrangeIcon size='MD' color='#6A6C72' icon={item.icon} />
                <h3 className='text-grayscale--500 fs-16 lh-20 fs-md-18 lh-md-22 mb-0'>{item.title}</h3>
                <p className='text-grayscale--400 fs-14 lh-17 mb-0'>{item.description}</p>
              </Card>
              ))}
          </ContainerDinDin>
        )
      }
    </SectionComponent>
  )
}

export default OsMelhoresBeneficios
