import React from 'react'
import Img from 'gatsby-image'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import OsMelhoresBeneficiosJSON from '../../assets/data/olha-quem-esta-guardando-dinheiro.json'
import usePageQuery from '../../pageQuery'

import InterPigMeuPorquinho from '../../assets/images/inter-pig-meu-porquinho.svg'

import { SuperAppInterProps } from '../../types'
import { ContainerCarousel, Card } from './styles'

type MelhoresBeneficiosProps = {
  icon: string;
  name: string;
  description: string;
}

const OlhaQuemEstaGuardandoDinheiro = ({ dataLayer, setDataLayer }: SuperAppInterProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const width = useWidth()
  const url = 'https://inter-co.onelink.me/Qyu7/jyc4nxhk'

  return (
    <SectionComponent
      id='olha-quem-esta-guardando-dinheiro'
      SectionStyles='bg-grayscale--100'
      backgroundSize='contain'
      pageDataImages={data}
      RowStyles='justify-content-md-start'
      backgroundPosition='center bottom -1px'
      minHeight={{ sm: '739px', md: '487px', lg: '535px', xl: '599px', xxl: '619px' }}
    >
      <div className='col-12 col-md-4 mb-3 d-flex justify-content-center'>
        <img src={InterPigMeuPorquinho} alt='Inter Pig Inter' width={194} height={194} />
      </div>
      <div className='col-12 col-md-7 col-lg-6 col-xl-5 mb-3 mb-md-0'>
        <h2 className='font-citrina text-grayscale--500 fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 mb-3'>
          <span className='d-md-block'>Olha quem está </span>guardando dinheiro
        </h2>
        <p className='text-grayscale--500 fs-14 lh-17 fs-md-16 lh-md-20 mb-4 mb-md-0'>
          Junte-se a mais de 25 milhões de pessoas que estão realizando o seu sonho com o Meu Porquinho.
        </p>
      </div>
      {width < WIDTH_MD &&
        (
          <a
            href={url}
            title='Ativar agora'
            className='btn btn--lg bg-orange--base text-white text-none'
            onClick={() => {
              setDataLayer(dataLayer)
              sendDatalayerEvent({
                section: 'dobra_09',
                section_name: 'Olha quem está guardando dinheiro',
                element_action: 'click button',
                element_name: 'Ativar agora',
                redirect_url: url,
              })
            }}
          >
            Ativar agora
          </a>
        )
      }
      <ContainerCarousel className='col-12 mt-2'>
        <DefaultCarousel
          sm={{ items: 1, partialVisibilityGutter: 70 }}
          md={{ items: 2, partialVisibilityGutter: 20 }}
          lg={{ items: 2, partialVisibilityGutter: 90 }}
          xl={{ items: 3, partialVisibilityGutter: 20 }}
          notShowDots
          notShowArrows
        >
          {OsMelhoresBeneficiosJSON.map((item: MelhoresBeneficiosProps) => (
            <Card
              className='d-flex position-relative justify-content-center flex-column'
              key={item.name}
            >
              <div className='d-flex'>
                <Img fluid={data[item.icon].fluid} alt='Icons' />
                <p className='text-grayscale--500 fs-12 lh-15 mb-0 pl-3 fw-600'>{item.name}</p>
              </div>
              <p
                className='text-grayscale--500 fs-12 lh-15 fs-md-14 lh-md-17 mb-0'
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </Card>
          ))}
        </DefaultCarousel>
      </ContainerCarousel>
    </SectionComponent>
  )
}

export default OlhaQuemEstaGuardandoDinheiro
