import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import SectionComponent from 'src/components/SectionComponent'

import { SuperAppInterProps } from '../../types'

import usePageQuery from '../../pageQuery'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

const OqueEoMeuPorquinho = ({ setOpenModal, dataLayer, setDataLayer }: SuperAppInterProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='o-que-e-o-meu-porquinho'
      bgMobile='bgOqueEoPorquinhoMobile'
      bgTablet='bgOqueEoPorquinhoTablet'
      bgDesktopLG='bgOqueEoPorquinhoDesktopLG'
      bgDesktopXL='bgOqueEoPorquinhoDesktopXL'
      bgDesktopXXL='bgOqueEoPorquinhoDesktopXXL'
      backgroundSize='contain'
      pageDataImages={data}
      backgroundPosition='center top'
      SectionStyles='py-5 bg-white'
      minHeight={{ sm: '644px', md: '352px', lg: '514px', xl: '638px', xxl: '638px' }}
    >
      <div className='col-12 col-md-6 col-xxl-5'>
        <Img
          fluid={data.oQueEoMeuPorquinho.fluid}
          alt='Inter Pig meditando com os olhos fechados e várias palavras de dinheiro ao redor'
          className='mb-5'
          style={{ width: '100%', height: '92%' }}
        />
      </div>
      <div className='col-12 col-md-6 col-lg-5 pr-xl-5'>
        <h2 className='font-citrina text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-md-3'>
          <span className='d-lg-block'>O que é o Meu</span> Porquinho?
        </h2>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22'>
          O Meu Porquinho do Inter é a oportunidade perfeita para aqueles que possuem objetivos financeiros e
          desejam guardar o dinheiro parado na conta para desfrutar quando precisar.
        </p>
        <p className='text-grayscale--500 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-4'>
          E o melhor de tudo, é gratuito, rende todo mês e você fica feliz :)
        </p>
        {width < WIDTH_MD
          ? (
            <a
              href='https://inter-co.onelink.me/Qyu7/jyc4nxhk'
              title='Ativar agora'
              className='btn btn--lg bg-orange--base text-white text-none'
              onClick={() => {
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'O que é o Meu Porquinho?',
                  element_action: 'click button',
                  element_name: 'Ativar agora',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/jyc4nxhk',
                })
              }}
            >
              Ativar agora
            </a>)
          : (
            <button
              title='Ativar agora'
              className='btn btn--lg bg-orange--base text-white text-none'
              onClick={() => {
                setOpenModal && setOpenModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'O que é o Meu Porquinho?',
                  element_action: 'click button',
                  element_name: 'Ativar agora',
                })
              }}
            >
              Ativar agora
            </button>
          )
        }
      </div>
    </SectionComponent>
  )
}

export default OqueEoMeuPorquinho
